<template>
  <div>
    <div v-if="!isExplorer">
      <span :title="row['c_subscriber']">{{ row['c_subscriber'] }}</span>
    </div>
    <ExplorerLink v-else :explorer-name="explorerName"/>
  </div>
</template>
<script>
import ExplorerLink from '@/components/dynamic/ExplorerLink';

export default {
  components: {ExplorerLink},
  props: {
    row: Object,
  },
  computed: {
    isExplorer() {
      return this.row['n_type']=='Explorer'? true:false; // type id 8 , kai will update in BE
    },
    explorerName() {
      return this.row['c_subscriber'];
    },
  },
};
</script>